import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import axios from "axios";
// custom router
import router from "./router";

Vue.config.productionTip = true

Vue.use(VueRouter)
Vue.prototype.httpReq = axios;

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
