import AppLayout from "./layouts/AppLayout.vue";
import VueRouter from "vue-router";

const router = new VueRouter({
    mode: "hash",
    routes: [
    {
        path: "/",
        component: AppLayout,
        children: [{
            path: "/",
            redirect: "/home"
        }, {
            path: "/home",
            component: () => import("./pages/home.vue")
        }]
    }, {
        path: "*",
        component: () => import("./pages/home.vue")
    }]
})

export default router