<template>
  <router-view></router-view>
</template>

<script>

import "./assets/css/slide.css";
import "./assets/css/custom.css";
import "./assets/css/mobile.css";
import "./assets/css/tablet.css";

export default {
  name: 'App',
  components: {
  }
}
</script>

<style></style>
